// NPM Requirements
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import Helmet from 'react-helmet';

// Actions
import locationActions from '../../actions/locationActions';

// Selectors
import { getLocation } from '../../selectors';

// Misc
import { formatDisplayTime } from '../../modules/helpers';
import IsoMorphUtils from '../../modules/isoMorphUtils';

class BoeingLocation extends Component {
  constructor(props) {
    super(props);
    const match = props.computedMatch || props.match;
    this.id = match.params.id;
  }

  componentDidMount() {
    this.props.clearLocations().then(() => {
      this.props.fetchLocation(this.id);
    });
  }

  render() {
    const { locationResource } = this.props;
    if (!locationResource) return null;

    let name = locationResource.name ? locationResource.name : locationResource.filtered_address;

    let eventsList = locationResource.events.map((event) => {
      const date = moment(event.start_time).format('dddd, MMMM Do');
      const startTime = formatDisplayTime(event.start_time);
      const endTime = formatDisplayTime(event.end_time);

      let trucksList = event.trucks.map((truck) => {
        let porterLink = 'https://order.seattlefoodtruck.com/menu/' + locationResource.pod.porter_id + '/locations?availability=go';
        let truckIsActive = moment(event.start_time).isBefore(moment().endOf('day').add(4, 'days'));
        if (truckIsActive && truck.porter_id && locationResource.pod && locationResource.pod.porter_id) {
          porterLink = 'https://order.seattlefoodtruck.com/menu/' + locationResource.pod.porter_id + '/locations/' + truck.porter_id + '/categories?availability=go';
        }
        return (
          <div className='BoeingTruck' key={"truck-" + truck.id}>
            <a href={porterLink}>
              <div className='LocationIcon'>
                { truck.featured_photo &&
                  <div
                    className="LocationIcon-img"
                    style={{ backgroundImage: `url("${IsoMorphUtils.railsContext.imageBase}/${truck.featured_photo}")` }}
                  />
                }
              </div>
              <div>
                <h3>{truck.name}</h3>
                <p>{truck.food_categories_string}</p>
              </div>
            </a>
          </div>
        );
      });

      return (
        <React.Fragment key={"event-" + event.id}>
          <div className='BoeingEvent'>
            <h2>{date}</h2>
            <p>{startTime} - {endTime}</p>
          </div>

          {trucksList}
        </React.Fragment>
      );
    });

    return (
      <React.Fragment>
        <Helmet>
          <title>{name}</title>
        </Helmet>

        <header>
          <div className='Overlay'>
            <h1>{name}</h1>
            {locationResource.pod &&
              <p>{locationResource.pod.description}</p>
            }
          </div>
        </header>

        {eventsList}

      </React.Fragment>
    );
  }
}

BoeingLocation.propTypes = {
  fetchLocation: PropTypes.func,
  clearLocations: PropTypes.func,
  location: PropTypes.object,
  match: PropTypes.object,
  computedMatch: PropTypes.object,
  history: PropTypes.object
};

function mapStateToProps(state, props) {
  return {
    locationResource: getLocation(state, props),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchLocation(id) {
      let params = {
        include_events: true,
        include_trucks: true,
        weeks_ahead: 1,
      }
      dispatch(locationActions.fetchOne(id, params));
    },
    clearLocations() {
      return dispatch(locationActions.clear());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BoeingLocation));
